export const init = () => {
  // Main
  // ---------------------
  initLogin();
  initOtp();
  initForgot();
  initDeviceListing();
  initDeviceDetail();
  initMobilePlanListing();
  initMobilePlanDetail();
  initBillingPage();
  initShoppingCart();
  initOrderConfirmation();
  initOrderListing();
  initOrderDetail();
  initTicketListing();
  initTicketDetail();
  initFormBuilder();
  initReportUsersListing();
  initMyReportsReport();
  initBillReportDetail();

  // Component Related
  // ---------------------
  initAccountPage();
  initChildUsersPage();
  initChildUserOrdersPage();
};

const initFormBuilder = () => {
  const formBuilder = document.getElementById("form-builder");

  if (formBuilder) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "FormBuilderContainer" */ "./containers/FormBuilderContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: FormBuilder },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (formBuilder) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <FormBuilder />
              </Provider>
            </LocalizationProvider>,
            formBuilder
          );
        }
      }
    );
  }
};

const initLogin = () => {
  const login = document.getElementById("login");

  if (login) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "LoginContainer" */ "./containers/LoginContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: Login },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (login) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <Login />
              </Provider>
            </LocalizationProvider>,
            login
          );
        }
      }
    );
  }
};

const initOtp = () => {
  const otp = document.getElementById("otp");

  if (otp) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "OtpContainer" */ "./containers/OtpContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: Otp },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (otp) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <Otp />
              </Provider>
            </LocalizationProvider>,
            otp
          );
        }
      }
    );
  }
};

const initForgot = () => {
  const forgot = document.getElementById("forgot-password");

  if (forgot) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "ForgotPasswordContainer" */ "./containers/ForgotPasswordContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: ForgotPassword },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (forgot) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <ForgotPassword />
              </Provider>
            </LocalizationProvider>,
            forgot
          );
        }
      }
    );
  }
};

const initReset = () => {
  const reset = document.getElementById("reset-password");

  if (reset) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "ForgotPasswordContainer" */ "./containers/ForgotPasswordContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: ForgotPassword },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (forgot) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <ForgotPassword />
              </Provider>
            </LocalizationProvider>,
            forgot
          );
        }
      }
    );
  }
};

const initDeviceListing = () => {
  const productListing = document.getElementById("device-listing");

  if (productListing) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "DeviceListingContainer" */ "./containers/DeviceListingContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: ProductListing },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (productListing) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <ProductListing />
              </Provider>
            </LocalizationProvider>,
            productListing
          );
        }
      }
    );
  }
};

const initDeviceDetail = () => {
  const deviceDetail = document.getElementById("device-detail");

  if (deviceDetail) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "DeviceDetailContainer" */ "./containers/DeviceDetailContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: DeviceDetail },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (deviceDetail) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <DeviceDetail />
              </Provider>
            </LocalizationProvider>,
            deviceDetail
          );
        }
      }
    );
  }
};

const initMobilePlanListing = () => {
  const mobilePlanListing = document.getElementById("mobile-plan-listing");

  if (mobilePlanListing) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "MobilePlanListingContainer" */ "./containers/MobilePlanListingContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: MobilePlanListing },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (mobilePlanListing) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <MobilePlanListing />
              </Provider>
            </LocalizationProvider>,
            mobilePlanListing
          );
        }
      }
    );
  }
};

const initMobilePlanDetail = () => {
  const mobilePlanDetail = document.getElementById("mobile-plan-detail");

  if (mobilePlanDetail) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "MobilePlanDetailContainer" */ "./containers/MobilePlanDetailContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: MobilePlanDetail },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (mobilePlanDetail) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <MobilePlanDetail />
              </Provider>
            </LocalizationProvider>,
            mobilePlanDetail
          );
        }
      }
    );
  }
};

const initBillingPage = () => {
  const billingPage = document.getElementById("billing-page");

  if (billingPage) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(/* webpackChunkName: "ModalProvider" */ "./context/ModalContext"),
      import(
        /* webpackChunkName: "BillingPageContainer" */ "./containers/BillingPageContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { ModalProvider, ModalManager },
        { default: BillingPage },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;
        const manager = new ModalManager({ ownerDocument: window?.document });

        if (billingPage) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <ModalProvider manager={manager}>
                  <BillingPage />
                </ModalProvider>
              </Provider>
            </LocalizationProvider>,
            billingPage
          );
        }
      }
    );
  }
};

const initShoppingCart = () => {
  const shoppingCart = document.getElementById("shopping-cart");

  if (shoppingCart) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "ShoppingCartContainer" */ "./containers/ShoppingCartContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: ShoppingCart },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (shoppingCart) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <ShoppingCart />
              </Provider>
            </LocalizationProvider>,
            shoppingCart
          );
        }
      }
    );
  }
};

const initOrderConfirmation = () => {
  const orderConfirmation = document.getElementById("order-confirmation");

  if (orderConfirmation) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "OrderConfirmationContainer" */ "./containers/OrderConfirmationContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: OrderConfirmation },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (orderConfirmation) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <OrderConfirmation />
              </Provider>
            </LocalizationProvider>,
            orderConfirmation
          );
        }
      }
    );
  }
};

const initOrderListing = () => {
  const orderListing = document.getElementById("order-listing");
  if (orderListing) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "OrderListingContainer" */ "./containers/OrderListingContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: OrderListing },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (orderListing) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <OrderListing />
              </Provider>
            </LocalizationProvider>,
            orderListing
          );
        }
      }
    );
  }
};

const initOrderDetail = () => {
  const orderDetail = document.getElementById("order-detail");
  if (orderDetail) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "OrderDetailContainer" */ "./containers/OrderDetailContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: OrderDetail },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (orderDetail) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <OrderDetail />
              </Provider>
            </LocalizationProvider>,
            orderDetail
          );
        }
      }
    );
  }
};

const initTicketListing = () => {
  const ticketListing = document.getElementById("ticket-listing");
  if (ticketListing) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "TicketListingContainer" */ "./containers/TicketListingContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: TicketListing },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (ticketListing) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <TicketListing />
              </Provider>
            </LocalizationProvider>,
            ticketListing
          );
        }
      }
    );
  }
};

const initTicketDetail = () => {
  const ticketDetail = document.getElementById("ticket-detail");
  if (ticketDetail) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "TicketDetailContainer" */ "./containers/TicketDetailContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: TicketDetail },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (ticketDetail) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <TicketDetail />
              </Provider>
            </LocalizationProvider>,
            ticketDetail
          );
        }
      }
    );
  }
};

const initMyReportsReport = () => {
  const MyReports = document.getElementById("my-reports");
  if (MyReports) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "MyReportsContainer" */ "./containers/MyReportsContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: BillUsageDetail },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (MyReports) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <BillUsageDetail />
              </Provider>
            </LocalizationProvider>,
            MyReports
          );
        }
      }
    );
  }
};

const initReportUsersListing = () => {
  const reportUsersListing = document.getElementById("report-users-listing");
  if (reportUsersListing) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "ReportUsersListingContainer" */ "./containers/ReportUsersListingContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: TicketListing },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (reportUsersListing) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <TicketListing />
              </Provider>
            </LocalizationProvider>,
            reportUsersListing
          );
        }
      }
    );
  }
};

const initBillReportDetail = () => {
  const billUsageDetail = document.getElementById("bill-report-detail");
  if (billUsageDetail) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "BillReportDetailsContainer" */ "./containers/BillReportDetailsContainer"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: BillReportDetails },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        if (billUsageDetail) {
          ReactDOM.render(
            <LocalizationProvider resources={getResources()}>
              <Provider store={store}>
                <BillReportDetails />
              </Provider>
            </LocalizationProvider>,
            billUsageDetail
          );
        }
      }
    );
  }
};

const initAccountPage = () => {
  const accountPage = document.getElementById("account-page");
  if (accountPage) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(/* webpackChunkName: "ModalProvider" */ "./context/ModalContext"),
      import(
        /* webpackChunkName: "AccountPage" */ "./components/AccountPage/AccountPage"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { ModalProvider, ModalManager },
        { default: AccountPage },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;
        const manager = new ModalManager({ ownerDocument: window?.document });

        const isParent = accountPage.getAttribute("data-parent");

        ReactDOM.render(
          <LocalizationProvider resources={getResources()}>
            <Provider store={store}>
              <ModalProvider manager={manager}>
                <AccountPage isParent={isParent == 1} />
              </ModalProvider>
            </Provider>
          </LocalizationProvider>,
          accountPage
        );
      }
    );
  }
};

const initChildUsersPage = () => {
  const page = document.getElementById("child-users-page");
  if (page) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(/* webpackChunkName: "ModalProvider" */ "./context/ModalContext"),
      import(
        /* webpackChunkName: "ChildUsersPage" */ "./components/ChildUsersPage/ChildUsersPage"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { ModalProvider, ModalManager },
        { default: ChildUsersPage },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;
        const manager = new ModalManager({ ownerDocument: window?.document });

        const isParent = page.getAttribute("data-parent");

        ReactDOM.render(
          <LocalizationProvider resources={getResources()}>
            <Provider store={store}>
              <ModalProvider manager={manager}>
                <ChildUsersPage isParent={isParent == 1} />
              </ModalProvider>
            </Provider>
          </LocalizationProvider>,
          page
        );
      }
    );
  }
};

const initChildUserOrdersPage = () => {
  const page = document.getElementById("child-user-orders-page");
  if (page) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(/* webpackChunkName: "ModalProvider" */ "./context/ModalContext"),
      import(
        /* webpackChunkName: "ChildUserOrdersPage" */ "./components/ChildUserOrdersPage/ChildUserOrdersPage"
      ),

      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { ModalProvider, ModalManager },
        { default: ChildUserOrdersPage },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;
        const manager = new ModalManager({ ownerDocument: window?.document });

        const isParent = page.getAttribute("data-parent");

        ReactDOM.render(
          <LocalizationProvider resources={getResources()}>
            <Provider store={store}>
              <ModalProvider manager={manager}>
                <ChildUserOrdersPage isParent={isParent == 1} />
              </ModalProvider>
            </Provider>
          </LocalizationProvider>,
          page
        );
      }
    );
  }
};
