// import './_spin';
import "./plugins/jquery-ui/jquery-ui.min";
import "bootstrap/dist/js/bootstrap.min";
import $ from "jquery";
import "../../js/main/app/lib/plugins/sticky/jquery.sticky-kit.min"

require("jquery-mousewheel")($);
require("malihu-custom-scrollbar-plugin")($);

(function ($) {
  "use strict";

  let site = {
      breakpoints: {
        xs: 420,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: 1600,
      },
      scrolling: false,
      cache: {},
      timeout: {},
      templates: {},
    },
    spin = {
      lines: 13,
      length: 1,
      width: 5,
      radius: 6,
      scale: 1,
      corners: 1,
      color: "#FFF",
      opacity: 0.25,
      rotate: 0,
      direction: 1,
      speed: 1,
      trail: 60,
      fps: 20,
      zIndex: 2e9,
      className: "spinner",
      top: "50%",
      left: "0",
      shadow: false,
      hwaccel: false,
      position: "absolute",
    },
    autocomplete,
    componentForm = {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      administrative_area_level_1: "short_name",
      country: "short_name",
      postal_code: "short_name",
    };

  // obj

  function resize_product_carousel_width() {
    var window_width = $(window).width(),
      sidebar_width = 0,
      slider_width = 0;

    if (window_width < 768) {
      slider_width = "100%";
    } else {
      // Set max width on window
      if (window_width > 1800) {
        window_width = 1800;
      }

      $(".product-carousel-container").width(0);
      $(".sidebar").each(function () {
        var $this = $(this);
        if ($this.is(":visible")) {
          sidebar_width += $this.outerWidth(true);
        }
      });

      slider_width = window_width - sidebar_width;
    }
    $(".product-carousel-container").width(slider_width);
  }

  function responsiveMenu(flag) {
    var $menu = $("#menu-box");
    if (flag) {
      $menu.removeClass("default").addClass("responsive");
    } else {
      $menu.removeClass("responsive").addClass("default");
    }
  }

  function resize(event) {
    var $window = $(window),
      $active = $("#menu");
    if ($window.width() < site.breakpoints.md) {
      // reponsive menu
      responsiveMenu(true);

      // affix elements
      affixElements(false);
    } else {
      // reponsive menu
      responsiveMenu(false);

      // affix elements
      affixElements(true);

      // body status
      $("body").removeClass("menu-active");
    }

    // Resize Product Carousel
    resize_product_carousel_width();
  }

  function affixElements(flag) {
    /*var $el = $('#menu-bar, #website-alerts, #shopping-nav'),
            offset = $('#content').offset().top - $('#menu-bar').outerHeight(true);
        if (flag) {
            if ($el.data('bs.affix')) {
                $el.data('bs.affix').options.offset = offset;
            } else {
                $el.affix({
                    offset: offset
                });
            }
        } else {
            if ($el.data('bs.affix')) {
                $el.data('bs.affix').options.offset = 0;
            } else {
                $el.affix({
                    offset: 0
                });
            }
        }*/
  }

  function checkValue(input) {
    var $input = input,
      required = $input.attr("required"),
      type = $input.attr("type"),
      name = $input.attr("name"),
      flag = true,
      value = $input.val();

    // false / empty
    if (!required) {
      return flag;
    }

    // type
    switch (type) {
      // email
      case "email":
        if (!/[A-Za-z0-9_.\-]+@([A-Za-z0-9_\-]+\.)+[A-Za-z]{2,4}/.test(value)) {
          flag = false;
        }
        break;
      case "checkbox":
        if (!$input.prop("checked")) {
          flag = false;
        }
        break;
      // default
      default:
        if (value === "" || value === null) {
          flag = false;
        }
        break;
    }

    return flag;
  }

  function checkFields(form) {
    var $fields = form
        .find("input:visible, textarea:visible, select:visible")
        .not("[type=submit]"),
      flag = true;

    // cycle fields
    $fields.each(function () {
      var $input = $(this),
        $wrapper = $input.parent(),
        value = $input.val(),
        name = $input.attr("name"),
        message = $wrapper.data("alert") || "This field is required.",
        $alert = $("<div />").addClass("alert-message").text(message);

      // remove old alert
      $wrapper.removeClass("alert").find(".alert-message").remove();

      if (!checkValue($input)) {
        // append alert
        $wrapper.addClass("alert"); //.append($alert);
        $alert.fadeIn(300);
        flag = false;
      }
    });

    return flag;
  }

  function removeBrowserFormValidate() {
    $("form").attr("novalidate", "novalidate");
  }

  function applyFilters(ignore) {
    var include = $("#filters").data("include")
        ? $("#filters").data("include").split(",")
        : [],
      ignore = ignore || [],
      pageURL = $("#filters").data("url"),
      $price = $("#price"),
      price = $price.length ? $price.val().split(",") : [],
      initialPrice = $price.data("min") + "," + $price.data("max"),
      data = {};

    // normal filters
    $("#filters .filter-list [data-filter]").each(function (index) {
      var $el = $(this),
        id = $el.data("id"),
        value = $el.attr("title"),
        key = $el.closest(".filter-box").data("filter");

      // ignore
      if (ignore.indexOf(key) != -1) return;

      // active ones
      if ($el.is(".active")) {
        if (data[key] === undefined) {
          data[key] = [];
        }
        data[key].push(id);
      }
    });

    // price
    if (ignore.indexOf("price") == -1 && price != initialPrice) {
      if (typeof price[0] != "undefined") {
        data.min = price[0];
      }
      if (typeof price[1] != "undefined") {
        data.max = price[1];
      }
    }

    // search
    if (include.indexOf("search") != -1) {
      // include search
    }

    // sort
    data.sort = $("#sort-dropdown").val().split(",");

    $.map(data, function (val, i) {
      // replace ,
      if (typeof val != "undefined") {
        data[i] = val.toString();

        // remove empty values
        if (!data[i]) {
          delete data[i];
        }
      }
    });

    // convert to url format
    data = $.param(data);

    // fuck standards
    data = data.replace(/\=/g, "/");
    data = data.replace(/\&/g, "/");
    data = data.replace(/\%2C/g, "~");
    data = data.replace(/\%2F/g, "~~");

    window.location.href = pageURL + data;
  }

  /*------------------------------------*\
     GOOGLE MAPS
     \*------------------------------------*/

  /*
   *  render_map
   *
   *  This function will render a Google Map onto the selected jQuery element
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	$el (jQuery element)
   *  @return	n/a
   */

  function render_map($el) {
    // var
    var $markers = $el.find(".marker");

    var styles_array = [
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{color: "#4d4e49"}, {lightness: 0}],
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [{color: "#2b2c27"}, {lightness: 0}],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [{color: "#242621"}, {lightness: 0}],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{color: "#3d4039"}, {lightness: 0}, {weight: 0.1}],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [{color: "#555651"}, {lightness: 0}],
      },
      {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [{color: "#1f211c"}, {lightness: 0}],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{color: "#4d4e49"}, {lightness: 0}],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{color: "#4d4f4a"}, {lightness: 0}],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [{visibility: "on"}, {color: "#22231e"}, {lightness: 0}],
      },
      {
        elementType: "labels.text.fill",
        stylers: [{saturation: 0}, {color: "#d3d3d1"}, {lightness: 0}],
      },
      {elementType: "labels.icon", stylers: [{visibility: "off"}]},
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{color: "#686866"}, {lightness: 0}],
      },
      {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [{color: "#535550"}, {lightness: 0}],
      },
      {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [{color: "#fefefe"}, {lightness: 0}, {weight: 1.2}],
      },
    ];

    // vars
    var args = {
      zoom: 14,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      panControl: false,
      zoomControl: true,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.LARGE,
        position: google.maps.ControlPosition.LEFT_CENTER,
      },
      scrollwheel: false,
      styles: styles_array,
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function () {
      add_marker($(this), map);
    });

    // center map
    center_map(map);
  }

  /*
   *  add_marker
   *
   *  This function will add a marker to the selected Google Map
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	$marker (jQuery element)
   *  @param	map (Google Map object)
   *  @return	n/a
   */

  function add_marker($marker, map) {
    // var
    var latlng = new google.maps.LatLng(
      $marker.attr("data-lat"),
      $marker.attr("data-lng")
    );

    // create marker
    var myIcon = new google.maps.MarkerImage(
      "https://i.imgur.com/TFBuRtt.png",
      null,
      null,
      null,
      new google.maps.Size(29, 46)
    );
    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
      icon: myIcon,
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($marker.html()) {
      // create info window
      var infowindow = new google.maps.InfoWindow({
        content: $marker.html(),
      });

      // show info window when marker is clicked
      google.maps.event.addListener(marker, "click", function () {
        infowindow.open(map, marker);
      });
      infowindow.open(map, marker);
    }
  }

  /*
   *  center_map
   *
   *  This function will center the map, showing all markers attached to this map
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	map (Google Map object)
   *  @return	n/a
   */

  function center_map(map) {
    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(
        marker.position.lat(),
        marker.position.lng()
      );
      bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length == 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
      map.setZoom(14);
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }
  }

  // ---------------- render google maps
  $(window).on("load", function () {
    $(".gmap").each(function () {
      var $this = $(this);

      render_map($this);
    });
  });


  $(window).scroll(function () {
    var sticky = $('.header'),
      scroll = $(window).scrollTop();

    if (scroll >= 100) sticky.addClass('fixed');
    else sticky.removeClass('fixed');
  });

  $('.increament').click(function () {
    var $input = $('#qty');
    $input.val(+$input.val() + 1);
  });
  $('.decreament').click(function () {
    var $input = $('#qty');
    $input.val(+$input.val() + -1);
  })
  // ---------------- modal nav
  $(".modal.instagram").on("click", ".prev, .next", function (event) {
    event.preventDefault();
    var $el = $(this),
      $current = $el.closest(".modal"),
      $currentID = $current.attr("id"),
      $currentTrigger = $(".instagram-list [data-target=#" + $currentID + "]"),
      $next,
      $nextTrigger,
      $nextID;

    if ($el.is(".prev")) {
      $nextTrigger = $currentTrigger.parent().prev().children();
      if (!$nextTrigger.length) {
        $nextTrigger = $(".instagram-list [data-target]").last();
      }
    } else {
      $nextTrigger = $currentTrigger.parent().next().children();
    }

    $nextID = $nextTrigger.data("target");
    $next = $($nextID);

    $current.modal("hide");
    $next.modal("show");
  });

  // instagram trigger
  $(".instagram-item").click(function (event) {
    event.preventDefault();

    var $this = $(this),
      $modal = $("#collection-1"),
      caption = $this.find(".caption").val(),
      img_src = $this.find(".thumb_insta_img").attr("src");

    // fetch the Modal
    $modal.find(".title_caption").html(caption);
    $modal.find(".modal-insta-img").find("img").attr("src", img_src);

    // create UL for related products
    var html = "";
    $this.find(".related_products").each(function (index) {
      var link = $(this).attr("data-link");
      var title = $(this).attr("data-title");
      var price = $(this).attr("data-price");
      var img = $(this).attr("data-img");

      html +=
        "<li>" +
        '<a href="' +
        link +
        '" title="' +
        title +
        '">' +
        '<div class="row no-padding vcenter">' +
        '<div class="col-xs-3">' +
        '<figure class="photo">' +
        "<div>" +
        "<span>" +
        '<img src="' +
        img +
        '" alt="">' +
        "</span>" +
        "</div>" +
        "</figure>" +
        "</div>" +
        '<div class="col-xs-7">' +
        '<p class="title">' +
        title +
        "</p>" +
        "</div>" +
        '<div class="col-xs-2 tx-right hidden-xs">' +
        '<p class="action">View</p>' +
        "</div>" +
        "</div>" +
        "</a>" +
        "</li>";
    });

    // fetch related product container
    $modal.find(".products-list").html(html);

    $modal.modal("show");
  });

  /*------------------------------------*\
   GENERAL
  \*------------------------------------*/

  // ---------------- backdrop click
  $("body").on("click", "#backdrop", function () {
    $("body").removeClass("sidebar-active menu-active");
  });

  // ---------------- inputs blur validation
  $("body").on("blur", "input, select, textarea", function () {
    var $input = $(this),
      $wrapper = $input.parent();
    checkFields($wrapper);
  });

  // ---------------- inputs focus
  $("body").on("focusin focusout", ".field", function () {
    $(this).closest(".field-label").toggleClass("focus");
  });

  // ---------------- input check
  $("body")
    .on("click", ".field[type=checkbox], .field[type=radio]", function () {
      var $el = $(this),
        $label = $el.closest(".field-label"),
        type = $(this).attr("type"),
        name = $(this).attr("name");

      if (type === "radio") {
        $(".field[name=" + name + "]")
          .closest(".field-label")
          .removeClass("checked");
      }

      if (!$el.prop("checked")) {
        $label.removeClass("checked");
      } else {
        $label.addClass("checked");
      }
    })
    .each(function () {
      $(this).triggerHandler("click");
    });

  // ---------------- scroll to
  $("[data-scrollto]").on("click", function (event) {
    event.preventDefault();
    var target = $(this).data("scrollto"),
      offset =
        $(window).width() < site.breakpoints.md
          ? $("#header").outerHeight(true)
          : 70;
    $("html, body").animate(
      {
        scrollTop: $(target).offset().top - offset,
      },
      1000
    );
  });

  // ---------------- select one
  $(".select-one-item").on("click", function (event) {
    event.preventDefault();
    var $el = $(this),
      $box = $el.closest(".select-one-box"),
      $items = $box.find(".select-one-item"),
      $collapse = $box.find(".collapse-box");

    if ($el.is(".active") || $box.find(".collapsing").length) {
      event.stopPropagation();
      return false;
    }

    // remove old one
    $items.not($el).removeClass("active");
    // set active
    $el.addClass("active");
  });

  // ---------------- read more
  $(".read-more").on("click", function (event) {
    event.preventDefault();
    $(this).removeClass("read-more");
  });

  /*------------------------------------*\
   FORMS
  \*------------------------------------*/

  // ---------------- form submissions
  removeBrowserFormValidate();
  $("body").on("submit", "form", function () {
    return checkFields($(this));
  });

  // ---------------- form swap
  $(".form-swap").on("click", function (event) {
    event.preventDefault();
    var $current = $(this).closest("form"),
      $form = $($(this).data("form"));
    $form.show();
    $current.hide();
  });

  // ---------------- search
  $("#search-form").on("submit", function (event) {
    event.preventDefault();
    var $form = $(this),
      url = $form.attr("action"),
      value = $form.find("input[name=query_input]").val(),
      slug = encodeURI(value.toLowerCase().replace(/ +/g, "-"));
    if (value) {
      // redirect to search page
      window.location.href = url + "?q=" + value;
    } else {
      window.location.href = url;
    }
  });

  /*------------------------------------*\
    TOGGLES
  \*------------------------------------*/


  /*------------------------------------*\
    MENU
  \*------------------------------------*/

  // ---------------- menu (default)
  $("body").on(
    "focusin mouseenter",
    "#menu-bar.default #menu > li > a",
    function (event) {
      var $el = $(this),
        $item = $el.parent(),
        $submenu = $item.find(".submenu-bar"),
        $carousel = $item.find(".carousel"),
        initialHeight =
          $("#menu > li.active .submenu-bar").outerHeight(true) || 0,
        finalHeight = $submenu.stop(true, true).outerHeight(true);

      // reset others
      $("#menu > li").not($item).removeClass("active");
      $(".submenu-bar").not($submenu).stop(true).height("auto").hide();

      // no submenu
      if (!$submenu.length) {
        return;
      }

      // active item
      $item.addClass("active");

      // animate menu
      $submenu.stop(true).height(initialHeight).show().animate(
        {
          height: finalHeight,
        },
        200
      );
    }
  );
  $("body").on("mouseleave", "#menu-bar.default #menu", function (event) {
    var $item = $("#menu > li.active"),
      $submenu = $(".submenu-bar");

    // remove active item
    $item.removeClass("active");

    // animate menu
    $submenu
      .stop(true, true)
      .clearQueue()
      .slideUp(100)
      .delay(100)
      .queue(function () {
        $(this).height("auto");
        $(this).dequeue();
      });
  });
  // ---------------- menu (responsive)
  $("body").on(
    "click",
    "#menu-bar.responsive #menu > li > a",
    function (event) {
      var $el = $(this),
        $item = $el.parent(),
        $submenu = $item.find(".submenu-bar");
      if (!$submenu.length) {
        return;
      }

      event.preventDefault();
      // reset others
      $("#menu > li").not($item).removeClass("active");
      $(".submenu-bar").not($submenu).stop(true).slideUp(200);

      // active item
      $item.toggleClass("active");

      // animate menu
      $submenu.stop(true).slideToggle(200);
    }
  );

  // ---------------- scroll bar
  $(".side-nav, .filter-content").mCustomScrollbar({
    scrollInertia: 600,
  });

  // ---------------- filter toggle
  $("body").on("click", ".filter-box .title", function (event) {
    event.preventDefault();
    var $filter = $(this).closest(".filter-box"),
      $list = $filter.find(".filter-content");
    // toggle class
    $filter.toggleClass("active");
    // show/hide list
    $list.stop(true).slideToggle(function () {
      $(document.body).trigger("sticky_kit:recalc");
    });
  });

  // ---------------- toggle submenu categories
  $("body").on("click", ".filter-list li.sub > a", function (event) {
    event.preventDefault();
    var $item = $(this).closest("li.sub");
    $item.toggleClass("active");
  });

  // ---------------- range
  $("#slider-range").slider({
    range: true,
    min: 0,
    max: 5000,
    step: 250,
    values: [1000, 3000],
    slide: function (event, ui) {
      $("#price").val(ui.values[0] + "," + ui.values[1]);
      $("#price-value .min").text("$" + ui.values[0]);
      $("#price-value .max").text("$" + ui.values[1]);
      $("#price-range button[type=submit]").removeAttr("disabled");
    },
  });

  /*------------------------------------*\
        CREATE ACCOUNT
  \*------------------------------------*/

  // ---------------- create account
  $("[data-create-account]").on("click", function (event) {
    event.preventDefault();

    var $form = $(this).closest("form"),
      $fields = $form.find(".field"),
      url = $form.attr("action"),
      data = $form.serialize(),
      flag = false,
      $submit = $(this),
      $submitAux = $submit.children("span"),
      spinner = $.extend(true, {}, spin);

    // prevent multiple clicks
    if ($submit.is(".loading")) {
      return false;
    }

    // validation errors
    if (!checkFields($form)) {
      return false;
    }

    // send data
    $.ajax({
      type: "POST",
      url: url,
      data: data,
      cache: false,
      dataType: "json",
      beforeSend: function () {
        // add loader
        $submit.addClass("loading");
        $submitAux.spin(spinner);
      },
      success: function (response) {
        console.log(response);
        createAlert(response);

        if (response.status) {
          setTimeout(function () {
            window.location.href = response.url;
          }, 1000);
        }

        // remove loader
        $submit.removeClass("loading");
        $submitAux.spin(false);
      },
      error: function (response) {
        console.log(response);
        // create alert
        createAlert(response);

        // remove loader
        $submit.removeClass("loading");
        $submitAux.spin(false);
      },
    });
  });

  /*------------------------------------*\
        CONTACT
  \*------------------------------------*/

  // ---------------- default send form
  $("[data-send-form-old]").on("click", function (event) {
    event.preventDefault();
    var $form = $(this).closest("form"),
      $fields = $form.find(".field"),
      url = $form.data("action") || $form.attr("action"),
      data = $form.serialize(),
      flag = false,
      resetOnSuccess = $form.data("reset-on-success") || false,
      $submit = $(this),
      $submitAux = $submit.children("span"),
      spinner = $.extend(true, {}, spin);

    // prevent multiple clicks
    if ($submit.is(".loading")) {
      return false;
    }

    // validation errors
    if (!checkFields($form)) {
      return false;
    }

    // send data
    $.ajax({
      type: "POST",
      url: url,
      data: data,
      cache: false,
      dataType: "json",
      beforeSend: function () {
        // add loader
        $submit.addClass("loading");
        $submitAux.spin(spinner);
      },
      success: function (response) {
        // create alert
        createAlert(response);

        // remove loader
        $submit.removeClass("loading");
        $submitAux.spin(false);

        // reset fields
        if (resetOnSuccess) {
          $form[0].reset();
        }

        if (typeof response.url !== "undefined") {
          window.location.href = response.url;
        }
      },
      error: function (response) {
        // create alert
        createAlert(response);

        // remove loader
        $submit.removeClass("loading");
        $submitAux.spin(false);
      },
    });
  });

  /*------------------------------------*\
        WINDOW EVENTS
    \*------------------------------------*/

  /// ---------------- window events
  $(window).on("resize", resize);
  //$(window).on('hashchange', hashChange);
  //$(window).on('scroll', scroll);
  $(window).triggerHandler("resize");
  //$(window).triggerHandler('hashchange');
  //$(window).triggerHandler('scroll');
})(jQuery);
