import "./scripts";
import { init as initReact } from "./react/main.js";

/*------------------------------------*\
  DOM Binding
\*------------------------------------*/

import(/* webpackChunkName: "Misc" */ "./dom/faq").then((Faq) =>
  Faq.binds()
);

/*------------------------------------*\
  Initialize React
\*------------------------------------*/
initReact();
